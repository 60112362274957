.pos-rel{
    position: relative;
}
#apidocs{
  background-color: #fff;
  .react-bootstrap-table .NewTable th {
    border-top: none;
  }
  .react-bootstrap-table {
    min-height: auto!important;
  }
    #pos-sticky{
        position: sticky!important;
        top: 50px;
        position: -webkit-sticky;
    }
    .NewOverFlowScroll::-webkit-scrollbar {
      width: 10px;
      height: 8px;
      border-radius: 20px;
    }
 .borderbottomtop{
    border-top: 1px solid #DBDEE4;
    border-bottom: 1px solid #DBDEE4;
 }
 .borderleftright{
  border-left: 1px solid #DBDEE4;
  border-right: 1px solid #DBDEE4;
 }
 .borderS{
border: 1px solid #DBDEE4;
border-top-right-radius: 8px;
border-top-left-radius:8px ;
 }
 .border-top-left{
  border-top-right-radius: 8px;
border-top-left-radius:8px ;
 }
 .border-bottom-left{
  border-bottom-right-radius: 8px;
border-bottom-left-radius:8px ;
 }
 .commonRow{
    text-align: center;
    align-items: center;
    border-left: 1px solid #DBDEE4;
 }
 .width{
    width: 100px!important;
 }
 .width2{
    width: 140px;
 }
 .copy{
    font-size: 15px;
    cursor: pointer;
    position: absolute;
    right: 15px;
    top:15px;
 }
 .mtr-heading{
background-color: #f8f8f8;
color:#687182;
font-size: 22px;
padding-left: 1.1rem;
font-weight: 600;
 }
 .mt25rem{
    margin-top: 2.5rem;
 }
    .req{
        height: 21rem;
        overflow: auto;
        position: relative;
        border-radius: 8px;
    }
    .req2{
      height: 15rem;
    overflow: auto;
    position: relative;
    border-radius: 8px;
    }
    .apidoccontainer{
        margin-left: 10rem;
        margin-right: 10rem;
    }
    .apiborderbox{
        border: 1px solid #DBDEE4;
        border-radius: 0.25rem;
        color: #687182;
        font-style: italic;
        width: 75%;
    }
    .px-5-api{
        padding-left: 3rem!important;
        padding-right: 3rem!important;
    }
    @media only screen and (max-width:480px){
        .px-5-api{
            padding-left: 0rem!important;
            padding-right: 0rem!important;
        }
        .pb-18 {
            padding-bottom: 0px !important;
        }
        .pt-18 {
            padding-top: 0px;
        }
      }
      .width3{
        width: 15rem;
      }
      .h10rem{
      height: 10rem!important;
      }
    .h32rem {
        height: 32rem!important;
    }
    @media only screen and (max-width:768px){
        .h32rem{
            height: 21rem!important;
        }
    }
    .nav-tabs .nav-link.active{
        background-color: #2E3336;
    color: #fff;
    font-weight: bold;
    border: none;
    }
    .api-tab-container{
        background-color: #1E2224;
        a{
            color: #fff;
        }
    }
   
    .tab-content{
        background-color: #2E3336;
        height: 43rem;
       overflow: auto;
       padding: 1.5rem;
        color: #fff;
       pre{
        overflow:unset!important;
       }
    }
    .codeAPI{
        background-color: #2E3336!important;
    }
    .RightArrow{
      height: 19px;
      width: 19px;
    }
    
    .sidebar {
        margin: 0;
        padding: 0;
        background-color: #fff;
        min-height: 272px;

        /* width: auto !important; */
      }
      .activeMenuNew{
        background-color: #4169E1 ;
        color:#fff
      }
      .sidebar a {
        display: block;
        color: #111827;
        padding: 16px;
        text-decoration: none;
        font-size: 15px;
        font-weight: 500;
      }
      
      .sidebar a.active {
        background-color: #4CAF50;
        color: white;
        font-weight: bold;
      }
      
      .sidebar a:hover:not(.activeMenu) {
        background-color: var(--color-primary-blue-light);
        color:#4169E1 ;
        font-weight: 400;
        .RightArrow{
          filter: brightness(0) saturate(100%) invert(57%) sepia(83%) saturate(5648%) hue-rotate(214deg) brightness(91%) contrast(92%);    }
      }
      .activeMenu {
        background-color: #4169E1 ;
        color:#fff!important;
        font-weight: 500;
        .RightArrow{
          filter:brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(273deg) brightness(103%) contrast(103%)!important;
        }
       
      }
   
      img.vi-info-img {
        width: 75%;
        height: auto;
      }
      
      .vi-info-p {
        font-weight: 400;
        line-height: 1.75;
        color: #414652;
        font-size: 14px;
      }
      
      .vi-info-p iframe {
        width: 99% !important;
        height: 545px;
      }
      
      .vi-info-p p,
      .vi-info-p ul li,
      .vi-info-p ol li {
        font-size: 13px;
        text-align: justify;
      }
      
      .vi-info-blue-block {
        font-size: 30px;
        height: 55px;
        line-height: 22px;
        padding: 15px 0;
        width: 55px;
        font-weight: 500;
        margin-top: 5px;
        float: left;
        margin: 0 20px 0 0;
        text-align: center;
        background-color: rgb(27, 50, 114);
        color: #fff;
      }      
      pre {
        background: #f7f7f7;
        padding: 15px;
        font-size: 14px;
        width: 75%;
        color: #687182!important;
       
      }
      .section-pb-50 {
        padding-bottom: 1.5rem!important;
      }
      .font-size-25 {
        font-size: 25px;
    }
    .section-pb-50 .table {
      font-size: 12px;
  }
  .font-color-grey {
    color: #6a707b;
}
.font-size-14 {
    font-size: 14px;
}
.pb-18 {
  padding-bottom: 18px !important;
}
.mtr-heading-New{
  font-size: 13px;
  color:#414652;
}
.react-bootstrap-table{
  border-radius: 8px;
}
.subHeading{
  color: #687182;
  font-size: 14px;
}
.table{
  margin-bottom: 0rem;
}
.card{
  margin-bottom: 1rem;
  border: 1px solid #DBDEE4;
}
.NewTable{
  border: none;
  tr:hover{
    background-color: #fff;
  }
}
}
.__json-pretty__{
  background: #f7f7f7;
  padding: 15px;
  font-size: 14px;
  width: 100%!important;
  color: #687182 !important;
}
.mt-100{
  margin-top: 85px;
}
.NewOverFlowScroll::-webkit-scrollbar {
  width: 10px;
  border-radius: 20px;
}

/* Track */
.NewOverFlowScroll::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
.NewOverFlowScroll::-webkit-scrollbar-thumb {
  background: #C5C4C8;
  border-radius: 10px;
}

.NewOverFlowScroll::-webkit-scrollbar-thumb:hover {
  background: #C5C4C8;
}
.NewOverFlowScroll{
  overflow-y: auto;}