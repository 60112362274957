.header-ps{
    .header-padding{
        padding: 12px 12px;
    }
    background-color: #4169E1!important;
    .login-desi{
        background-color: #4169E1!important;
        border: 1px solid #fff!important;
        padding: 4px 20px;
        margin: 0px 20px;
        font-size: 14px;
    }
    .btn-w-135{
        width: 190px !important;
        background-color: #C1CFF6 !important;
        color: #000!important;
        border-color: #C1CFF6 !important;
        padding: 4px 20px!important;
        margin: 0px 20px;
    }
    .custom{
        color: #fff;
        font-weight: 600;
        font-size: 23px;
    }
    .navbar-brand img{
   width : 16rem!important;
    }
}
.navbar-light .menulinks .nav-link   {
    color: #fff!important;
    font-weight: 600;
    font-size: 15px;
}
.m20px{
    margin: 0px 20px;
}

@media (min-width:800px) and (max-width:1200px){
   .btn-w-135,.login-desi,.m20px{
        margin: 0px 5px;
    }
    
}
@media (max-width:800px){
    .btn-w-135,.login-desi,.m20px{
         margin: 10px 5px!important;
     }
     
 }
// .nav-link{
//     margin: 0px 2rem!important;
// }