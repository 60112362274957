.chatbox-container {
    border-radius: 5px;
    bottom: 40px;
    box-shadow: 5px 5px 13px rgba(91,81,81,.4);
    display: flex;
    justify-content: center;
    margin: 40px 0;
    position: fixed;
    right: 40px;
    z-index: 6;
}
.botbutton{
    background-color: #3454B4;
    border: none;
    border-radius: 25px;
    bottom: 15px;
    height: 50px;
    position: fixed;
    right: 40px;
    width: 50px;
    z-index: 6;
}
.react-chatbot-kit-chat-container {
    position: relative;
    width: 360px;
}
.react-chatbot-kit-chat-bot-message-container {
   display:none ;
}
.ChatHeading{
   color: #fff;
   font-size: 20px;
   font-weight: 600;
}
.react-chatbot-kit-chat-bot-message{
    margin-left: 0px;
}
.chatbot-container{
   margin-top: 10px;
    border: 1px solid #d9dbe4;
    padding: 1rem 2rem 2rem 2rem;
    box-shadow: 0 2px 3px rgba(0,0,0,.08);
    border-radius: 5px;
    background: #4169E1;
    border-radius: 8px;
 textarea{
    font-weight: 400;
    font-size: .812rem;
    width: 100%;
    border-radius: 5px;
    resize: none;
    height: 5rem;
 }
 input{
    font-weight: 400;
    font-size: .812rem;
    width: 100%;
    border-radius: 5px;
    resize: none;
 }
 ::placeholder{
   font-style: italic;
 }
 .mb-4x{
   margin-bottom: 2.2rem;
 }
 .requiredMSG{
   color: #fff;
   font-size: 13px;
   font-weight: 400;
   font-style: italic;
 }
 select{
   font-weight: 400;
   font-size: .812rem;
   width: 100%;
   border-radius: 5px;
   resize: none;

}
 .submitbtn{
    margin-top: 1rem;
    border-radius: 5px;
    overflow: hidden;
    text-transform: none;
    display: inline-block;
    box-sizing: border-box;
    padding: 0.5rem 1rem;
    vertical-align: middle;
    font-size: 0.8rem;
    border: 1px solid #ACC9D4;
    line-height: 1rem;
    text-align: center;
    text-decoration: none;
    background-color: #ACC9D4;
    color: #000;
 }
 label{
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 15px;
    line-height: 1.38;
    color: #2c3241!important;
 }
 }
 .react-chatbot-kit-chat-input-form{
    cursor: not-allowed;
 }
 .react-chatbot-kit-chat-input-container{
    cursor: not-allowed!important;
    display: none;
 }
 .bot-avatar{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 1.2rem;
    margin-right: 12.5px;
    background-color: #4169E1;
    display: flex;
    justify-content: center;
    align-items: center;
    p{
        color: #fff;
        margin: 0;
        padding: 0;
    
    }
 }
 .alert-div{
    background-color: #46798D;
    color: #fff;
    font-size: 12px;
 }
 .react-chatbot-kit-chat-bot-message{
   width: 80%;
 }
 .no-arrow {
   appearance: textfield;
    }
    .no-arrow::-webkit-inner-spin-button {
      display: none;
    }
    .no-arrow::-webkit-outer-spin-button,
    .no-arrow::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .react-chatbot-kit-chat-bot-message{
      span{
         font-size: 13px;
      }
    }
    .react-chatbot-kit-chat-message-container{
      overflow-x: hidden;
   }
   @media(max-device-width:449px) {
      .react-chatbot-kit-chat-message-container {
          height: 300px;
      }
      .react-chatbot-kit-chat-inner-container {
          height: 400px;
      }
  }