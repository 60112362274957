.landing-page-main-class{
    .purple-background{
        background-color: #4169E1!important;
    }
    .font-fff{
        color: #fff!important;
    }
@media (min-width: 1200px){

.container-xl-ps{
    max-width: 1300px!important;
}
}
section.section-1 {

 padding-top: 100px!important;
 padding-bottom: 0px!important;

  }
  
  section.section-2 {
    padding: 50px 0;
  }
  
  section.section-3 {
    padding: 50px 0;
  }
  
.section-grey {
    padding: 50px 0;
    /* opacity: 0.3; */
    background-color: #EAEFFC;
  }
.gradient-bg{
  background: linear-gradient(90deg,#EAEFFC,#ffffff);
}
  .title-heading {
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    padding-bottom: 25px;
  }
  
  .home-in {
    text-decoration: none !important;
  }
  
  .in-cursor {
    cursor: pointer;
  }
  .font-size-12 {
    font-size: 15px;
   }
   .font-size-14 {
    font-size: 17px;
   }
   p {
    font-size: 1rem;
}
  .Early-Access-Release,
  .Made-For-a-Purpose {
    font-size: 42px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color:#4169E1;
    padding-bottom: 16px;
  }
  
  .section-1-title {
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    padding-bottom: 26px;
    font-size: 19px;
    font-weight: 600;
    color: #000;
  }
  .custom-btn{
    padding: 15px 15px!important;
    font-size: 19px!important;
  }
  // .img-div{
  //   object{
  //     width: 80%;
  //     margin-left: 6rem;
  //   }
  // }
  .img-div .screenviewimg {
  max-width: 200%;
  padding-top: 45px;
  }
  .img-div  .relativeimg{
 position: absolute;
 padding: 0px 0px;
 top: 22rem;
 left: -1rem;
  }
  .img-div  .relativeimg2{
    position: absolute;
    padding: 0px 0px;
    top: 10.5rem;
    right: -9rem;
    max-width: 90% !important;
    height: 34%;
     }
  .Sign-up-for-Free  {
    
    padding: 0.6rem 2.7rem;
      font-weight: bold;
      letter-spacing: 1.7px;
      text-align: center;
      background-color: #ACC9D4  !important;
      color: #000;
      border-color: #ACC9D4  !important;
      font-size: 16px !important;
      border-radius: 6px !important;
  }
  .Sign-up-for-Free-2  {
    
    padding:0.6rem 2.7rem;
    font-weight: bold;
    letter-spacing: 1.7px;
    text-align: center;
    background-color: #4169E1!important;
    color: #fff;
    border-color: #4169E1 !important;
    font-size: 16px !important;
    border-radius: 6px !important;
    border-width: 2px;
}
.Sign-up-for-Free-3  {
    
  padding:0.6rem 2.7rem;
  font-weight: bold;
  letter-spacing: 1.7px;
  text-align: center;
  background-color: #C1CFF6 !important;
  color: #000 !important;
  border-color: #C1CFF6 !important;
  font-size: 16px !important;
  border-radius: 6px !important;
  border-width: 2px;
}
.Sign-up-for-Free-2:hover{
  background-color: #2E499D!important;
  color: #fff ;
  border-color: #3454B4!important;
}
.Sign-up-for-Free-2:focus{
  background-color: #2E499D!important;
  color: #fff ;
  border-color: #3454B4!important;
}
.Sign-up-for-Free-3:hover{
  background-color: #C1CFF6 !important;
  color: #000 !important;
}
.Sign-up-for-Free-3:focus{
  background-color: #C1CFF6 !important;
  color: #000 !important;
}
.fs-21px{
  font-size:21px!important;
}
  .apibtn{
    padding: 12px 25px;
    font-weight: bold;
    letter-spacing: 1.7px;
    text-align: center;
    background-color: #4169E1  !important;
    color: #fff;
    border-color: #4169E1 !important;
    font-size: 18px !important;
}
  
  
  .section-2 .ticker-sm img {
    border-radius: 25%;
    object-fit: contain;
    width: 24px;
    height: 24px;
  }
  
  .section-2 .ticker-num{
    font-size: 26px;
    font-weight: bold;
    color: #2b292d;
    vertical-align: middle;
    padding-left: 10px;
  }
  
  .section-2 .ticker-title {
    font-weight: 600;
    color: #2b292d;
    padding-top: 5px;
  }
  
  .purpose-sm .purpose-heading{
    font-weight: 600;
    text-align: center;
    padding-top: 10px;
    color:#000;
  }
  
  .purpose-sm  img {
    object-fit: contain;
    width: 45px;
    height:45px;
  }
  
  .purpose-sm .purpose-grey-text{
    line-height: 1.2;
    text-align: center;
    color: #000000;
    padding-top: 10px;
    margin: 0 35px;
  }
  
  .purpose .Made-For-a-Purpose {
    text-align: center;
  }
  
  .purpose p {
    font-weight: 500;
    line-height: 1.33;
    text-align: center;
    color: #2b292d;
    margin: 0px 50px;
  }
  
  .blue-section {
    background-color: #2b3e9d;
    padding: 40px 0 !important;
    position: relative;
  }
  
  
  img.img-top {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
  }
  
  img.img-bottom {
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
  }
  
  .brand-tag {
    font-weight: bold;
    line-height: 1.21;
    text-align: center;
    color: #131415;
  }
  
  .service-tag {
    font-weight: bold;
    line-height: normal;
    color: #999999;
    padding-bottom: 15px;
  }
  
  .mtr-pl {
    font-weight: 500;
    line-height: 1.33;
    color: #2b292d;
    margin: 0px 50px 0 0;
    text-align: justify;
  }
  
  .mtr-sm {
    margin-left: -12px;
  }
  
  .mtr-sm img {
    border-radius: 25%;
    object-fit: contain;
    width: 30px;
    height: 40px;
  }
  
  .mtr-text {
    margin-top: -31px;
    margin-left: 40px;
    width: 60%;
  }
  
  .mtr-heading {
    font-weight: 600;
    padding-bottom: 10px;
  }
  
  .mtr-grey-text {
    opacity: 0.7;
    line-height: 1.2;
    color: #000000;
  }
  
  p.feature {
    font-weight: bold;
    line-height: 1.55;
    color: #f1592b;
    padding-bottom: 10px;
  }
  
  p.feature-pl {
    font-weight: 600;
    line-height: 1.65;
    letter-spacing: normal;
    color: #666666;
  }
  
  .feature-img {
    width: 100%;
    height: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
    /* box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05), 0 1px 3px 0 rgba(0, 0, 0, 0.1), inset 0 1px 0 0 rgba(255, 255, 255, 0.1); */
    background-blend-mode: overlay, normal;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)), linear-gradient(to bottom, #ffffff, #ffffff);
  }
  .get-start-btn {
    padding: 8px 20px !important;
    font-size: 11px;
    background-color: #1b3272;
    color: #fff;
    border: 1px solid #1b3272;
    border-radius: 3px !important;
    font-weight: 600;
    letter-spacing: .5px;
  }
  
  .new-letter-img {
    width: 100%;
    height: auto;
    margin-top: -60px;
  }
  
  .started-btn {
    padding: 8px 20px;
    font-size: 12px;
    background-color: #3040c4;
    color: #fff;
    border: 1px solid #3040c4;
    border-radius: 3px !important;
  }
  
  .aml-api-pera {
    font-weight: 500;
    line-height: 1.33;
    text-align: center;
    color: #2b292d;
    margin: 0px 50px;
  }
  
  #mc_form input {
    width: 68% !important;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-image: none;
    background-clip: padding-box;
    border: 1px solid #ced4da !important;
    border-radius: 2px;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    outline: none;
    max-width: 68%;
    margin-right: 0.25rem !important;
    margin-top: 10px;
  }
  
  .subscribe-hmc button {
    padding: 10px 20px !important;
    font-size: 12px !important;
    background-color: #3040c4 !important;
    color: #fff;
    border: 1px solid #3040c4 !important;
    border-radius: 3px !important;
  }
  
  .img-div .slick-list{
    height: 400px !important;
  }
  
  .early-h {
    font-weight: bold !important;
    padding-top: 10px;
    margin-bottom: 30px;
    color: #2a3e9d;
  }
  .demo-card{
    border-radius: 32px;
  }
  .my-6{
    margin: 4rem 0rem;
  }
  @media  (min-device-width:769px)and(max-device-width: 1440px) {
    section.section-1 {
      padding-bottom: 100px!important;
     
       }
.Early-Access-Release, .Made-For-a-Purpose {
    font-size: 30px;
}
.text-sm1-center p{
  font-size: .9375rem;
}
.img-div .screenviewimg{
  max-width: 100%;
  padding-top: 20px!important;
}
.img-div  .relativeimg{
  max-width: 50%;
  position: absolute;
  padding: 0px 0px;
  top: 12rem;
  left: -1rem;
   }
   
   .img-div .relativeimg2{
    position: absolute;
    padding: 0px 0px;
    top: 6.5rem;
    right: 3rem;
    /* max-width: 109% !important; */
    height: 30%;
    width: 30%;
}
  
  }
  
  @media (max-device-width :767px){
    section.section-1 {
      padding-bottom: 100px!important;
     
       }
       .img-div .screenviewimg{
        max-width: 100%;
        padding-top: 20px!important;
      }

    .img-div .relativeimg2{
      position: absolute;
      padding: 0px 0px;
      top: 4.5rem;
      right: 2.5rem;
      /* max-width: 109% !important; */
      height: 26%;
      width: 27%;
  }
  .img-div  .relativeimg{
    max-width: 50%;
    position: absolute;
    padding: 0px 0px;
    top: 8rem;
    left: -1rem;
     }
  // @media(max-device-width:820px) {
  //   .img-div.relativeimg{
  //     max-width: 50%;
  //     position: absolute;
  //     padding: 0px 0px;
  //     top: 18rem;
  //     left: -1rem;
  //   }
  //   .img-div.relativeimg2{
  //     position: absolute;
  //   padding: 0px 0px;
  //   top: 8.5rem;
  //   right: 4rem;
  //   /* max-width: 109% !important; */
  //   height: 30%;
  //   width: 30%;

  //   }
  // }
    }
    @media (width:768px) {
      section.section-1 {
        padding-bottom: 100px!important;
       
         }
      .img-div .screenviewimg{
        max-width: 100%;
        padding-top: 20px!important;
      }
      .img-div .relativeimg2{
        position: absolute;
        padding: 0px 0px;
        top: 8.5rem;
        right: 4rem;
        /* max-width: 109% !important; */
        height: 30%;
        width: 30%;
    
      }
      
    }
    .WeeklyNewsletter{
      color: #0C0C0C;
      font-size: 40px !important;
      line-height: 50px !important;
      font-weight: 700;
    }
    .input-email{
      padding: 14px;
      width: 64%;
      border: none;
      border-radius: 4px 0px 0px 4px;
      font-size: 16px;
      line-height: 21px;
    }
    .EmailSubmit{
      padding: 13px 80px;
      font-size: 18px;
      line-height: 27px;
      background-color: #ACC9D4  !important;
      color: #000;
      border-color: #ACC9D4  !important;
      border-radius: 8px;
      color: #fff;
    }
    .fadeInUp {
      animation: fadeInUp;
      animation-duration: 2s;
  }
  @keyframes fadeInUp {
     0% {
    opacity: 0;
    transform: translate3d(0,100%,0);
  }
  
  100% {
    opacity: 1;
    transform: none;
  }
  }
  .dflex{
    display: flex;
    flex-wrap: wrap;
  }
  .mailchimp-btn input{
    padding: 14px;
    width: 80%;
    border: none;
    border-radius: 4px 0px 0px 4px;
    font-size: 16px;
    line-height: 21px; 
    margin-right: 10px;
    flex-grow: 1;
    flex-basis: calc(100% / 3);
  }
  .mailchimp-btn button{
    padding: 13px 50px;
    font-size: 18px;
    line-height: 27px;
    border: none;
    background-color: #4169E1  !important;
    color: #fff;
    border-color:#4169E1  !important;
    border-radius: 8px;
    flex-grow: 1;
    flex-basis: calc(100% / 7);
  }
  .msg-alert{
    flex-basis: 100%; display: flex;
    p{
      margin-bottom: 0rem;
    }
  }
  @media (max-device-width :767px) {
    .mailchimp-btn button {
      padding: 13px 20px;
    }
  }
  @media (max-device-width:820px) {
    .mailchimp-btn button {
      padding: 13px 18px;
    }
  }
  .listImage{
    height: 22px;
    width:22px;
    padding-top: 10px;
    img{
      height: 12px;
    width: 27px;
    }
  }
  .listItem{
    margin-bottom: 0px;
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    color: #fff;
  }
  .fontrandom{
    color: #fff;
    font-size: 40px !important;
    line-height: 50px !important;
    font-weight: 700;
    padding-bottom: 30px;
    span{
      font-weight: 700 !important;
    color: #141F43 !important;
    }
  }
  .listGroup:hover{
    .listItem{
      font-weight: 600 !important;
    }
    .listImage img{
      content:url("../../assets/Blue Arrow.png") ;
    }
  }
  .trialButton{
    padding: 0.6rem 2.7rem;
    font-weight: bold;
    letter-spacing: 1.7px;
    text-align: center;
    background-color: #4169E1 !important;
    color: #fff;
    border-color: #fff !important;
    font-size: 16px !important;
    border-radius: 6px !important;
    border-width: 2px;
  }
  @media (max-device-width:500px){
    .trialButton , .Sign-up-for-Free, .Sign-up-for-Free-2{
      padding: 0.6rem 0.7rem!important;
      font-size: 15px!important;
    }
  }
  .home-insight-card {
    width: 235px !important;
    min-height: 475px!important;
    box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.09);
    background-color: #ffffff;
    border-radius: 10px;
    background-blend-mode: overlay, normal;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)), linear-gradient(to bottom, #ffffff, #ffffff);
  }
  
  .home-insight-card .card-body {
    padding: 15px 15px !important;
  }
  
  .home-insight-card .card-pera {
     font-weight: 600;
     line-height: 1.25;
     text-align: center;
     color: #a0acba;
     font-size: 12px;
     margin: 0rem;
  }
  
  .home-insight-card .card-text {
    font-weight: bold;
    letter-spacing: -0.24px;
    text-align: center;
    color: #2b292d;
    padding-top: 10px;
    font-size: 15px;
    min-height: 65px;
  }
  
  .home-insight-card .card-img-top {
    border-radius: 10px;
  }
  .home-insight-card {
    width: 100%!important;
}
@media (min-width: 768px)and(max-width:1200px){
  .home-insight-card{
    min-height: 420px!important;
  }
}
@media (min-width: 1200px){

  .container-xl-aml{
      max-width: 1300px!important;
  }
  }
  .font-size-16{
    font-size: 16px;
  }
  }
